import styled from 'styled-components'
import bg from '../../assets/images/bg.png'

import Section from '../../components/organisms/Section'
import {
  HOMEPAGE_DESCRIPTION,
  HOMEPAGE_TITLE_FIRST,
  HOMEPAGE_TITLE_LAST,
  SECTIONS,
} from '../../common/utils/constants'
import media from '../../common/utils/media'
import ScrollDown from '../../components/atoms/ScrollDown'
import Header from '../organisms/Header'
import Footer from '../organisms/Footer'

const HomePage = () => (
  <>
    <StyledTopSection>
      <Header />
      <div className="App-header">
        <h1>
          <span>{HOMEPAGE_TITLE_FIRST}</span> <span>{HOMEPAGE_TITLE_LAST}</span>
        </h1>
        <p>{HOMEPAGE_DESCRIPTION}</p>
        <div className="scroll-down-anim">
          <ScrollDown />
        </div>
      </div>
    </StyledTopSection>
    <StyledBody>
      {SECTIONS.map((section) => (
        <Section
          key={section.title}
          title={section.title}
          description={section.description}
          gridItems={section.gridItems}
        />
      ))}
    </StyledBody>
    <Footer />
  </>
)

export default HomePage

const StyledTopSection = styled.div`
  & {
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-size: cover;
    white-space: pre-line;
    font-family: montserrat;
    position: relative;

    div.App-header {
      min-height: calc(100vh - 129px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 1.4em;
      color: white;
      padding: 0 20%;

      ${media.tablet`
        min-height: calc(100vh - 89px);
        padding: 0 40px;
        font-size: calc(10px + 2vmin);
      `}

      ${media.sp`
        justify-content: start;
      `}
    }

    div.scroll-down-anim {
      position: absolute;
      bottom: 10px;

      ${media.sp`
        div {
          zoom: 0.8;
          -moz-transform: scale(0.8);
        }
      `}
    }

    h1 {
      font-family: montserrat;
      font-weight: 800;

      span:first-child {
        display: block;
        -webkit-animation: slideIn 1.4s forwards;
        -moz-animation: slideIn 1.4s forwards;
        animation: slideIn 1.4s forwards;
      }

      span:last-child {
        display: block;
        -webkit-animation: slideInFromRight 1.2s backwards;
        -moz-animation: slideInFromRight 1.2s backwards;
        animation: slideInFromRight 1.2s backwards;
        animation-delay: 0.3s;
      }
    }

    p {
      -webkit-animation: fadeIn 4s backwards;
      -moz-animation: fadeIn 4s backwards;
      animation: fadeIn 2s backwards;
      animation-delay: 1.4s;
    }
  }
`
const StyledBody = styled.div`
  div.section:nth-child(1) .grid-container {
    display: block;

    h4 {
      margin-block-start: 0;
    }

    div:nth-child(1) .all-contents-container {
      display: grid;
      grid-gap: 50px;
      grid-template-rows: auto 1fr;
      grid-template-columns: repeat(2, 1fr);
      justify-content: space-evenly;

      div {
        margin-top: 30px;
        border-top: 0;
        padding-top: 0;
      }
    }

    div:nth-child(2) {
      margin-top: 50px;
      border-top: 1px solid #8a979e;
      padding-top: 50px;
    }

    ${media.tablet`
        h3,p {
          text-align: center;
        }

        div:nth-child(1) .all-contents-container {
          display: block;
        }
    `}
  }

  div.section:nth-child(2) .grid-container {
    grid-template-columns: repeat(1, auto);

    ${media.pcAndTablet`
      margin: 20px 15vw;
    `}

    h3,
    p {
      text-align: center;
    }

    ${media.tablet`
        grid-template-columns: repeat(1, auto);
    `}
    ${media.sp`
        grid-template-columns: repeat(1, auto);
    `}
  }

  div.section:nth-child(2) {
    background-color: #15242f;
  }
`
