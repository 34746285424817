import styled from 'styled-components'
import { Router } from './Router'

function App() {

  return (
    <StyledApp>
      <Router />
    </StyledApp>
  )
}

export default App

const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`
